import { useLoaderData } from '@remix-run/react'
import { jwtDecode } from 'jwt-decode'
import React from 'react'
import { GENERATED_EMAIL } from '~/lib/auth'

const MAX_FILE_SIZE_IN_MB = 20

export const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MB * 1024 * 1024

export function parseJwt(token: string) {
  return jwtDecode(token)
}

export function singleton<Value>(name: string, value: () => Value): Value {
  const yolo = global as any
  yolo.__singletons ??= {}
  yolo.__singletons[name] ??= value()
  return yolo.__singletons[name]
}

export async function unwrap<T, U>(
  promise: Promise<T>,
  callback: (resolved: Awaited<T>) => Promise<U> | U,
) {
  return new Promise<U>(async (resolve, reject) => {
    try {
      const resolved = await promise

      const augmented = await callback(resolved)

      resolve(augmented)
    } catch (error) {
      reject(error)
    }
  })
}

export const sleep = (ms: number = 3000) => new Promise((resolve) => setTimeout(resolve, ms))

export function useLastLoaderData<T>() {
  const dataRef = React.useRef({})

  const data = useLoaderData<T>()

  React.useEffect(() => {
    if (data) {
      dataRef.current = data
    }
  }, [data])

  return data || (dataRef.current as typeof data)
}

export const chooseUserEmailOrPhone = ({
  email,
  phone,
}: {
  email: string
  phone?: string | null
}) => {
  if (email && !email.includes(GENERATED_EMAIL)) {
    return email
  } else {
    if (phone) {
      return phone
    } else {
      return null
    }
  }
}
