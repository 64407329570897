import * as Sentry from '@sentry/remix'
import { AxiosError } from 'axios'
import { set, get, camelCase } from 'lodash-es'
import { Api, HttpClient } from '~/generated/api'
import { authStore, logout } from './auth'
import { messagesStore } from './messages'

export interface HadrachaIssue {
  code: string
  detail: string
  attr: string
}

export interface HadrachaError {
  type: 'validation_error' | 'client_error' | 'server_error'
  errors: HadrachaIssue[]
}

export function formatIssues({ issues }: { issues: HadrachaIssue[] }) {
  const errorsObject: Record<string, string[]> = {}

  return issues.reduce((acc, issue) => {
    const key = issue.attr.includes('.')
      ? issue.attr
          .split('.')
          .map((key) => camelCase(key))
          .join('.')
      : camelCase(issue.attr)

    const message = issue.detail

    if (!get(acc, key)) {
      set(acc, key, [message])
    } else {
      get(acc, key).push(message)
    }

    return acc
  }, errorsObject)
}

export function serializeParams(params: Record<string, string>) {
  const queryString = []

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key]

      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryString.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
        })
      } else {
        queryString.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    }
  }

  return `${queryString.join('&')}`
}

export function apiClientFactory() {
  const { accessToken } = authStore.getState()

  const headers: HeadersInit = {}

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }

  // TODO: add support for multiple languages
  headers['Accept-Language'] = 'he'

  const httpClient = new HttpClient({
    headers,
    baseURL: import.meta.env.VITE_API_URL,
    paramsSerializer: {
      serialize: (params) => {
        const searchParams = serializeParams(params)

        return searchParams
      },
    },
  })

  return new Api(httpClient)
}

export async function handleExceptions(error: unknown) {
  if (error instanceof AxiosError) {
    const status = error.response?.status
    const data = error.response?.data as HadrachaError
    console.log({ status, data })
    switch (status) {
      case 401:
        Sentry.addBreadcrumb({
          category: 'auth',
          message: `User with an id of ${authStore.getState().userId} failed an authentication check while accessing the API`,
          level: 'error',
        })

        logout()
      case 400:
        if (data.type === 'validation_error') {
          return {
            errors: formatIssues({ issues: data.errors }),
            success: false,
          }
        }

        Sentry.captureException(error)

        const { addToast } = messagesStore.getState()

        addToast({
          type: 'error',
          description: String(data),
          title: 'Something went wrong',
        })

        return { errors: null, success: false }
      default:
        Sentry.captureException(error)
        console.log(error)
        return { errors: null, success: false }
    }
  }

  Sentry.captureException(error)

  return { errors: null, success: false }
}

export const api = apiClientFactory()
